import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { FirebaseFCMService } from './firebase.fcm.service';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.reducer';

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {

    public socketStatus = false;

    constructor(
        private socket: Socket,
        private firebaseFCMService: FirebaseFCMService,
        private store: Store<AppState>
    ) {
        this.checkStatus();
    }

    checkStatus() {

        this.socket.on('connect', () => {
            console.log('Connected into server');
            this.socketStatus = true;
        });

        this.socket.on('disconnect', () => {
            console.log('Disconnected of server');
            this.socketStatus = false;
        });
    }

    emit(evento: string, payload?: any, callback?: Function) {
        console.log('Emitiendo', evento);
        this.socket.emit(evento, payload, callback);
    }

    // Escuchar cualquier evento que emita el servidor
    listen(evento: string) {
        return this.socket.fromEvent(evento);
    }

    async loginWS(name: string, idUser: number, idPharmacy: string, firebaseToken: string) {
        try {
          //if (!firebaseToken) {
          //  console.error('No se pudo obtener el token FCM');
          //  throw new Error('Firebase FCM token no disponible');
          //}

           await new Promise((resolve, reject) => {
            this.emit('config-user', {
              type: 'F',
              name,
              idUser,
              idPharmacy: parseInt(idPharmacy),
              tokenFCM: firebaseToken
            }, (response: any) => {
              console.log('Server response for config-user:', response);

              if (response.error) {
                reject(response.error);
              } else {
                resolve(response);
              }
            });
          });

          setTimeout(async() => {

              await new Promise((resolve, reject) => {
                  this.emit('test-notification', {
              firebaseToken
            }, (response: any) => {
                if (!response) {
                  console.error('Respuesta vacía o indefinida');
                  reject(new Error('Respuesta vacía del servidor'));
                  return;
                }
                if (response.error) {
                  console.error('Error en test-notification:', response.error);
                  reject(response.error);
                } else {
                    console.log('Test de notificación exitoso', response);
                    resolve(response);
                }
            })
        });
    }, 2000)
    } catch (error) {
        console.error('Error during loginWS:', error);
        throw error;
        ``        }
      }

    logOUt(idCliente: any) {
        return new Promise((resolve: any, reject) => {
            console.log("Emitiendo disconnect");
            this.emit('disconnect', (response: any) => {
                console.log('Server response:', response);
                resolve(response);
            });
        });
    }

    // Eventos
    getNewOrder() {
        return this.listen('get-new-order');
    }

    quottedAccepted() {
        return this.listen('get-quotted-accepted');
    }

    getOrderCancelled() {
        return this.listen('get-order-cancelled');
    }

    emitQuottedOrder(ordenNumero: string, idUser: string, pharmacyName: string) {
        return new Promise((resolve: any, reject) => {
            console.log("Emitiendo quotted-order");
                this.emit('quotted-order', {
                    ordenNumero,
                    idUser,
                  //  pharmacyName
                }, (response: any) => {
                    console.log('Server response emit order:', response);
                    resolve(response);
                });
        })
    }

    emitOrderRejected(ordenNumero: string, idUser: string) {
        return new Promise((resolve: any, reject) => {
            console.log("Emitiendo order-rejected");
                this.emit('order-rejected', {
                    ordenNumero,
                    idUser
                }, (response: any) => {
                    console.log('Server response:', response);
                    resolve(response);
                });
        })
    }

    emitOrderPreparing(ordenNumero: string, idUser: string) {
        return new Promise((resolve: any, reject) => {
            console.log("Emitiendo order-preparing");
                this.emit('order-preparing', {
                    ordenNumero,
                    idUser
                }, (response: any) => {
                    console.log('Server response:', response);
                    resolve(response);
                });
        })
    }

    emitOrderReady(ordenNumero: string, idUser: string) {
        return new Promise((resolve: any, reject) => {
            console.log("Emitiendo order-ready");
                this.emit('order-ready', {
                    ordenNumero,
                    idUser
                }, (response: any) => {
                    console.log('Server response:', response);
                    resolve(response);
                });
        })
    }

    emitOrderDelivered(ordenNumero: string, idUser: string) {
        return new Promise((resolve: any, reject) => {
            console.log("Emitiendo order-delivered");
                this.emit('order-delivered', {
                    ordenNumero,
                    idUser
                }, (response: any) => {
                    console.log('Server response:', response);
                    resolve(response);
                });
        })
    }
}

export class CurrentUserModel {

    id: string;
    user: {
        name: string;
        type: string;
        idPharmacy: number;
    };
    tokenId: string;

    constructor(id: string) {
        this.id = id;
        this.user = {
            name: '',
            type: "F",
            idPharmacy: 0
        };
        this.tokenId = '';
    }
}
