import { Action } from "@ngrx/store";
import { Pharmacy } from "./../../interfaces/auth-interfaces";

export const SET_USER = '[Auth] Set User';
export const UNSET_USER = '[Auth] Unset User';
export const SET_CHOSEN_PHARMACY = '[Auth] Set Chosen Pharmacy';
export const UNSET_CHOSEN_PHARMACY = '[Auth] Unset Chosen Pharmacy';
export const SET_PHARMACIES = '[Auth] Set Pharmacies';
export const UNSET_PHARMACIES = '[Auth] Unset Pharmacies';
export const SET_TOKEN = '[Auth] Set Token';
export const UNSET_TOKEN = '[Auth] Unset Token';
export const UNSET_ALL = '[Auth] Unset All Auth Data';
export const SET_FIREBASE_TOKEN = '[Auth] Set Firebase Token';
export const UNSET_FIREBASE_TOKEN = '[Auth] Unset Firebase Token';


export class SetUserAction implements Action {
    readonly type=SET_USER;
    constructor( public user: any){
    }
}

export class UnsetUserAction implements Action {
    readonly type = UNSET_USER;
}

export class SetTokenAction implements Action {
    readonly type=SET_TOKEN;
    constructor( public token:string){
    }
}

export class UnsetTokenAction implements Action {
    readonly type=UNSET_TOKEN;
    constructor(){
    }
}

export class SetPharmacyAction implements Action {
    readonly type=SET_CHOSEN_PHARMACY;
    constructor( public pharmacy: Pharmacy){
    }
}

export class UnsetPharmacyAction implements Action {
    readonly type = UNSET_CHOSEN_PHARMACY;
}

export class SetPharmaciesAction implements Action {
    readonly type=SET_PHARMACIES;
    constructor( public pharmacies: Pharmacy[] | []){
    }
}

export class UnsetPharmaciesAction implements Action {
    readonly type = UNSET_PHARMACIES;
}

export class UnsetAllAction implements Action {
    readonly type = UNSET_ALL;
}

export class SetFirebaseTokenAction implements Action {
    readonly type = SET_FIREBASE_TOKEN;
    constructor(public token: string) {}
}

export class UnsetFirebaseTokenAction implements Action {
    readonly type = UNSET_FIREBASE_TOKEN;
}

export type authActions =   SetUserAction           |
                            UnsetUserAction         |
                            SetTokenAction          |
                            UnsetTokenAction        |
                            SetPharmacyAction       |
                            UnsetPharmacyAction     |
                            SetPharmaciesAction     |
                            UnsetPharmaciesAction   |
                            UnsetFirebaseTokenAction|
                            SetFirebaseTokenAction  |
                            UnsetAllAction;
