import { Action } from "@ngrx/store";

export const SET_ORDER_NOTIFICATION = '[Notification] Set Order Notification';
export const UNSET_ORDER_NOTIFICATION = '[Notification] Unset Order Notification';

export class SetOrderNotification implements Action {
    readonly type = SET_ORDER_NOTIFICATION;
    constructor(public amount: number) {}
}

export class UnsetOrderNotification implements Action {
    readonly type = UNSET_ORDER_NOTIFICATION;
}

export type notificationActions = SetOrderNotification | UnsetOrderNotification;