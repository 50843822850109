/*
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PersistenceService } from './services/persistence.service';
import { SocketIoConfigModule } from './socket-io-config/socket-io-config.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent {
    /!**
     * Constructor
     *!/
    constructor(
        private persistenceService: PersistenceService,
    ) {}

    ngOnInit(): void {
        this.persistenceService.hidratateStore();
    }

    ngOnDestroy(): void {
    this.persistenceService.remove_sesionStorage();

    }
}
*/


import { Component, inject, ViewChild } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { PersistenceService } from './services/persistence.service';
import {SocketIoConfigModule} from "./socket-io-config/socket-io-config.component";
import {WebsocketService} from "./services/websocket.service";
import { FirebaseFCMService } from './services/firebase.fcm.service';
// import { onMessage } from 'firebase/messaging';
import { OrderListComponent } from './modules/admin/apps/order-list/order-list.component';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.reducer';
import { SetFirebaseTokenAction } from './store/actions';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarModule, MatSnackBarRef} from '@angular/material/snack-bar';
import { MatDrawer } from '@angular/material/sidenav';
import { FuseDrawerComponent } from "../@fuse/components/drawer/drawer.component";
import { OrderService } from './services/order.service';
import { MatIconModule } from '@angular/material/icon';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, SocketIoConfigModule, MatSnackBarModule, FuseDrawerComponent, MatIconModule],
    providers: [PersistenceService, WebsocketService]
})
export class AppComponent {
    private _snackBar = inject(MatSnackBar);
    @ViewChild('recipeDrawer', {static: true}) recipeDrawer: MatDrawer;

    order:any;
    /**
     * Constructor
     */
    constructor(
        // private wsService: WebsocketService,
        private persistenceService: PersistenceService,
        private messagingService: FirebaseFCMService,
        private _wsService: WebsocketService,
        private store: Store<AppState>,
        private router:Router,
        private orderSvc:OrderService
    ) {}

    ngOnInit(): void {
        this.persistenceService.hidratateStore();
        let firebaseToken;
        this.messagingService.requestPermission().then((currentToken) => {
            firebaseToken = currentToken;
            this.store.dispatch(new SetFirebaseTokenAction( firebaseToken));
        })

        let name, userId, pharmacyId;
        pharmacyId = null;

        this.store.select('auth').subscribe(data =>
            {
                if(data.currentPharmacy !== null){
                    // console.log('data.currentPharmacy', data.currentPharmacy);
                    name = data.currentUser.name;
                    userId = data.currentUser.id;
                    pharmacyId = data.currentPharmacy.idFarmacia
                }
            }
        )
        if(pharmacyId == null){
            console.log('PHARMACY ID NULL');
        }
        if(pharmacyId !== null){
            console.log('PHARMACY ID NOT NULL');
            this._wsService.loginWS(name, userId, pharmacyId, firebaseToken);

            this._wsService.getNewOrder().subscribe(
                (res:any) => {
                    console.log('ENTRA GET NEW ORDER WS',res)
                    this.openSnackBar('¡Nueva orden de '+res.order.userName+'!',res.order.orderNumber)
                }
            );

            this._wsService.getOrderCancelled().subscribe(
                (res:any) => {
                    console.log("Orden cancelada:",res);
                    this.openSnackBar('¡Orden cancelada por '+res.order.userName+' Mariano!',res.order.orderNumber)
            }
            );

            this._wsService.quottedAccepted().subscribe(
                (res:any) => {
                    console.log("Orden aceptada:",res);
                    this.openSnackBar('¡Cotización aceptada por '+res.order.userName+'!',res.order.orderNumber)

                }
            )
        }
    }

    ngOnDestroy(): void {
        this.persistenceService.remove_sesionStorage();
    }

    openSnackBar(msj:any,orderNumber:any){

        const snackBarRef = this._snackBar.open(msj, 'Ver', {
            duration: 0,  // -1 Hace que el Snackbar permanezca hasta que el usuario lo cierre
            //panelClass: [''],
            horizontalPosition: 'end',
            verticalPosition: 'top',

        });

        snackBarRef.onAction().subscribe(async () => {
            this.recipeDrawer.toggle();
            await this.orderSvc.getOrderPromise(orderNumber)
            .then(res=>{
                console.log('RESPUESTA GET ORDER',res)
                this.order=orderNumber
            })
            .catch(err=>{
                console.log('ERROR GET ORDER',err)

            })


        //    this.router.navigateByUrl('apps/order-list')
        });
    }

}
