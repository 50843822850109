
import { Pharmacy } from './../../interfaces/auth-interfaces';
import * as fromAuth from '../actions/auth.action';

export interface AuthState{
    currentUser:        any;
    currentToken:       string;
    currentPharmacy:    Pharmacy;
    pharmaciesList:     Pharmacy[] | [];
    firebaseToken:      string;
}

const initialState: AuthState={
    currentUser:        null,
    currentToken:       null,
    currentPharmacy:    null,
    pharmaciesList:     null,
    firebaseToken:      null
};

export function authReducer (state=initialState,action:fromAuth.authActions): AuthState{
    switch (action.type) {
        case fromAuth.SET_USER:
            return{
                ...state,
                currentUser: {... action.user}
            };

        case fromAuth.UNSET_USER:
            return {
                ...state,
                currentUser: null,
            };

        case fromAuth.SET_TOKEN:
            return{
                ...state,
                currentToken: action.token
            };

        case fromAuth.UNSET_TOKEN:
            return{
                ...state,
                currentToken: null
            };

        case fromAuth.SET_CHOSEN_PHARMACY:
            return{
                ...state,
                currentPharmacy: {... action.pharmacy}
            };

        case fromAuth.UNSET_CHOSEN_PHARMACY:
            return {
                ...state,
                currentPharmacy: null,
            };
        case fromAuth.SET_PHARMACIES:
            return{
                ...state,
                pharmaciesList: [... action.pharmacies]
            };

        case fromAuth.UNSET_PHARMACIES:
            return {
                ...state,
                pharmaciesList: null,
            };

        case fromAuth.UNSET_ALL:
            return {
                currentUser:        null,
                currentToken:       null,
                currentPharmacy:    null,
                pharmaciesList:     null,
                firebaseToken:      null
            };
        case fromAuth.SET_FIREBASE_TOKEN:
            return{
                ...state,
                firebaseToken: action.token
            };
        case fromAuth.UNSET_FIREBASE_TOKEN:
            return{
                ...state,
                firebaseToken: null
            }
        default:
            return state;
    }
}

