import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.reducer';
import { environment } from 'environments/environment';
import { catchError, Observable, of, switchMap, tap, throwError } from 'rxjs';

import * as storeActions from '../store/actions';
import { CommonResponse } from 'app/interfaces/response-interfaces';
import { AuthService } from './auth.service';
import { BudgetMedDetail, MedicamentBudget } from 'app/interfaces/order-interfaces';
import { WebsocketService } from './websocket.service';


@Injectable({ providedIn: 'root' })
export class OrderService {
    private _authService = inject(AuthService);
    private _httpClient = inject(HttpClient);
    private _store = inject(Store<AppState>);
    private _wsService = inject(WebsocketService);

    baseUrl = environment.url;

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */

    get ordersList(): any {
        let ordersList=''
        this._store.select('order').subscribe(orderData => {
          ordersList = orderData.orderList;
        });
        return ordersList;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * Get orders by pharmacy
     */
    getOrdersByPharmacy(limit: number, page: number): Observable<any> {

        const pharmCUIT: string = this._authService.pharmacyChosenData.cuit;

        // Throw error, if the user is already logged in
        if (!pharmCUIT) {
            return throwError('CUIT number is required.');
        }

        return this._httpClient.get(`${this.baseUrl}/farmacia/orden?cuitFarmacia=${pharmCUIT}&page=${page}&size=${limit}&farmacia=true`, this._authService.headers_http).pipe(
            tap(response => console.log({response})),
            switchMap((response: any) => {
                this._store.dispatch(new storeActions.SetOrderListAction(response.response.ordenes));
                this._store.dispatch(new storeActions.SetResponseLengthAction(response.response.cantidadOrdenes));

                // Return a new observable with the response
                return of(response.response);
            })
        );
    }

    /**
     * Get history orders
     */
    getHistoryOrders(limit: number, page: number): Observable<any> {

        const pharmCUIT: string = this._authService.pharmacyChosenData.cuit;

        // Throw error, if the user is already logged in
        if (!pharmCUIT) {
            return throwError('CUIT number is required.');
        }

        return this._httpClient.get(`${this.baseUrl}/farmacia/orden?cuitFarmacia=${pharmCUIT}&page=${page}&size=${limit}&farmacia=true&historico=true`, this._authService.headers_http).pipe(
            tap(response => console.log({response})),
            switchMap((response: any) => {
                this._store.dispatch(new storeActions.SetOrderListAction(response.response.ordenes));
                this._store.dispatch(new storeActions.SetResponseLengthAction(response.response.cantidadOrdenes));

                // Return a new observable with the response
                return of(response.response);
            })
        );
    }

    /**
     * Get order by status
     * @param status status chosen
     * @param limit
     * @param page
     * @param urlPart
     * @returns
     */
    getOrdersByStatus(status: string, limit: number, page: number): Observable<any> {

        const pharmCUIT: string = this._authService.pharmacyChosenData.cuit;

        // Throw error, if the user is already logged in
        if (!pharmCUIT) {
            return throwError('CUIT number is required.');
        }

        if (!status) {
            return throwError('Status has not been selected.');
        }

        let url = `${this.baseUrl}/farmacia/orden?cuitFarmacia=${pharmCUIT}&estadoOrden=${status}&page=${page}&size=${limit}&farmacia=true`;

        return this._httpClient.get(url, this._authService.headers_http).pipe(
            tap(response => console.log({response})),
            switchMap((response: any) => {
                this._store.dispatch(new storeActions.SetOrderListAction(response.response.ordenes));
                this._store.dispatch(new storeActions.SetResponseLengthAction(response.response.cantidadOrdenes));

                // Return a new observable with the response
                return of(response.response);
            })
        );
    }

    /**
     * Get an order by order number
     * @param orderNumber
     * @param urlPart
     * @returns
     */
    getOrderByOrderNumberNew(orderNumber: string, urlPart: string): Observable<any>{
        // Throw error, if the user is already logged in
        if (!orderNumber) {
            return throwError('Order number is required.');
        }

        let url = `${this.baseUrl}/farmacia/orden?nroOrden=${orderNumber}&farmacia=true`;

        if(urlPart == 'historico'){
            url = url + '&historico=true'
        }

        return this._httpClient.get(url, this._authService.headers_http).pipe(
            tap(response => console.log({response})),
            switchMap((response: any) => {
                this._store.dispatch(new storeActions.SetCurrentOrderAction(response.response.ordenes[0]));

                // Return a new observable with the response
                return of(response.response.ordenes[0]);

            })
        );
    }

    /**
     * Change order status
     * @param statusData
     */
    changeOrderStatus(status: string, orderId: number,orderUserId:number,orderNumber:any): Observable<any> {

        // Throw error, if the user is already logged in
        if (!status) {
            return throwError('Status data is required.');
        }

        // Throw error, if the user is already logged in
        if (!status) {
            return throwError('Status data is required.');
        }

        const idPharmacy: number = Number(this._authService.pharmacyChosenData.idFarmacia);
        console.log({idPharmacy});


        const body = {
            estado: status,
            idUsuario: this._authService.userId
        }
        console.log('changeOrderStatus',body);

        return this._httpClient.put(`${this.baseUrl}/farmacia/orden/${orderId}`, body).pipe(
            // tap(response => console.log({response})),
            switchMap((response: CommonResponse) => {
                // console.log({response});
                if(response.statusCode !== 200){
                    return of(response.message)
                } else {
                    let pharmacyName: string = this._authService.pharmacyChosenData.razon_social;

                    switch (status) {
                        case 'P':
                            this._wsService.emit('order-preparing',{order:{pharmacyName,orderNumber,idPharmacy}, idUser:orderUserId});

                            break;

                        case 'L':
                            this._wsService.emit('order-ready',{order:{pharmacyName,orderNumber,idPharmacy}, idUser:orderUserId});

                            break;

                        case 'R':
                            this._wsService.emit('order-rejected',{order:{pharmacyName,orderNumber,idPharmacy}, idUser:orderUserId});

                            break;

                        default:
                            break;
                    }
                    // Return a new observable with the response
                    return of(response);
                }

            })
        );
    }

    /**
     * Change order status
     * @param statusData
     */
    deliverOrder(orderNumber: number,orderUserId:any): Observable<any> {

        let pharmacyId = this._authService.pharmacyChosenData.idFarmacia;

        // Throw error, if the user is already logged in
        if (!pharmacyId) {
            return throwError('Pharmacy id is required.');
        }

        // Throw error, if the user is already logged in
        if (!orderNumber) {
            return throwError('Order number is required.');
        }

        const body = {
            idUsuario: this._authService.userId,
            idFarmacia: pharmacyId,
            nroOrden: orderNumber
        }
        console.log({body});

        const idPharmacy: number = Number(pharmacyId);
        console.log({idPharmacy});

        return this._httpClient.post(`${this.baseUrl}/farmacia/orden/dispensa`, body).pipe(
            // tap(response => console.log({response})),
            switchMap((response: CommonResponse) => {
                // console.log({response});
                if(response.statusCode !== 200){
                    return of(response.message)
                } else {
                    let pharmacyName = this._authService.pharmacyChosenData.razon_social;
                    console.log('delivered-order',{order:{pharmacyName}, idUser:orderUserId})
                    this._wsService.emit('order-delivered',{order:{pharmacyName,orderNumber,idPharmacy}, idUser:orderUserId});

                    // Return a new observable with the response
                    return of(response.message);
                }

            })
        );
    }
    changeToFloat(value:any){
        return parseFloat(value)
      }

    createOrderBudget(medsArray: MedicamentBudget[], orderID: number,  orderUserId: number,orderNumber:any){
        let medsDetail: BudgetMedDetail[] = [];
        medsArray.forEach(med => {
            medsDetail.push({
                idMedication: med.medicationId,
                idMedicationRequest: med.medicationRequestId,
                precioVenta: med.precioVenta,
                porcentajeDescuento: 0,
                porcentajeCobertura: med.porcentajeCobertura
            })
        });
        console.log({medsDetail});

        const idPharmacy: number = Number(this._authService.pharmacyChosenData.idFarmacia);
        const body = {
            idOrden: orderID,
            idUsuario: this._authService.userId,
            detalleMedicamento: medsDetail
        }

        console.log('BODY COTIZAR',body)
        return this._httpClient.post(`${this.baseUrl}/farmacia/orden/cotizar`, body).pipe(
            switchMap((response: CommonResponse) => {
                console.log(response)
                if(response.statusCode !== 200){
                    return of(response.message)
                } else {

                    let pharmacyName = this._authService.pharmacyChosenData.razon_social;
                    console.log('quotted-order',{order:{pharmacyName,orderNumber}, idUser:orderUserId});

                    this._wsService.emit('quotted-order',{order:{pharmacyName,orderNumber,idPharmacy}, idUser:orderUserId});
                    // Return a new observable with the response
                    return of(response.message);
                }
            })
        );
    }

    getOrderPromise(orderNumber:any):Promise<any> {

        let url = `${this.baseUrl}/farmacia/orden?nroOrden=${orderNumber}&farmacia=true`;

        return new Promise<any | void>( (resolve,reject) => {
          this._httpClient.get(url, this._authService.headers_http).subscribe(
            {
              next(res:any) {
                resolve(res.response)
              },
              error(msg) {
                reject(msg)
              }
          });
        });
      }
}
