import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    inject,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
    IsActiveMatchOptions,
    RouterLink,
    RouterLinkActive,
} from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { FuseVerticalNavigationComponent } from '@fuse/components/navigation/vertical/vertical.component';
import { FuseUtilsService } from '@fuse/services/utils/utils.service';
import { Store } from '@ngrx/store';
import { WebsocketService } from 'app/services/websocket.service';
import { SetOrderNotification, UnsetOrderNotification } from 'app/store/actions';
import { AppState } from 'app/store/app.reducer';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'fuse-vertical-navigation-basic-item',
    templateUrl: './basic.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgClass,
        RouterLink,
        RouterLinkActive,
        MatTooltipModule,
        NgTemplateOutlet,
        MatIconModule,
    ],
    styleUrls: ['./basic.component.scss'],
})
export class FuseVerticalNavigationBasicItemComponent
    implements OnInit, OnDestroy
{
    inputNumber: number = 0;
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _fuseNavigationService = inject(FuseNavigationService);
    private _fuseUtilsService = inject(FuseUtilsService);
    private elementRef = inject(ElementRef);
    private _wsService = inject(WebsocketService);
    private _store = inject(Store<AppState>);
    @Input() item: FuseNavigationItem;
    @Input() name: string;

    // Set the equivalent of {exact: false} as default for active match options.
    // We are not assigning the item.isActiveMatchOptions directly to the
    // [routerLinkActiveOptions] because if it's "undefined" initially, the router
    // will throw an error and stop working.
    isActiveMatchOptions: IsActiveMatchOptions =
        this._fuseUtilsService.subsetMatchOptions;

    private _fuseVerticalNavigationComponent: FuseVerticalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Set the "isActiveMatchOptions" either from item's
        // "isActiveMatchOptions" or the equivalent form of
        // item's "exactMatch" option
        this.isActiveMatchOptions =
            this.item.isActiveMatchOptions ?? this.item.exactMatch
                ? this._fuseUtilsService.exactMatchOptions
                : this._fuseUtilsService.subsetMatchOptions;

        // Get the parent navigation component
        this._fuseVerticalNavigationComponent =
            this._fuseNavigationService.getComponent(this.name);

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Subscribe to onRefreshed on the navigation component
        this._fuseVerticalNavigationComponent.onRefreshed
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

            this._wsService.getNewOrder().subscribe(
                resp => {
                    if (window.location.pathname !== '/apps/order-list/list') {
                        this.inputNumber++;
                        console.log(this.inputNumber)
                        this._store.dispatch(new SetOrderNotification(this.inputNumber));
                        this._changeDetectorRef.markForCheck();
                    }
                }
            );
            this._store.select("notification").subscribe(
                resp => {
                    if (window.location.pathname !== '/apps/order-list/list') {
                    this.inputNumber = resp.order;
                    this._changeDetectorRef.markForCheck();
                    }
                }
            );
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    onClick(): void {
        if (window.location.pathname !== '/apps/order-list/list') {
            console.log(window.location.pathname)
            this.inputNumber = 0;
            this._store.dispatch(new UnsetOrderNotification());
        }
    }
}
