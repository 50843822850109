
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
//import * as models from './models/store.models'

import * as fromReducer from './reducers/';

export interface AppState{
    auth: fromReducer.AuthState;
    order: fromReducer.OrderState;
    map: fromReducer.MapState;
    notification: fromReducer.NotificationState
}

export const appReducers:ActionReducerMap<any,any>= {
    auth: fromReducer.authReducer,
    order: fromReducer.orderReducer,
    map: fromReducer.mapDataReducer,
    notification: fromReducer.notificationReducer
};

export function clearState(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    return function(state: AppState, action: Action): AppState {
        if (action.type === 'RESET_APP') {
          console.log('ENTRA CLEAR ')
          state = undefined;
      }
      return reducer(state, action);
    };
  }
  export const metaReducers: MetaReducer<AppState>[] = [clearState];
