import * as fromNotification from '../actions/notification.action';

export interface NotificationState{
    order: number
}

const initialState: NotificationState={
    order: 0
};

export function notificationReducer (state=initialState,action:fromNotification.notificationActions): NotificationState {
    switch (action.type) {
        case fromNotification.SET_ORDER_NOTIFICATION:
            return{
                ...state,
                order: action.amount
            };
        case fromNotification.UNSET_ORDER_NOTIFICATION:
            return {
                ...state,
                order: 0,
            };
        default:
            return state;
    }
}