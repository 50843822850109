<router-outlet></router-outlet>


<fuse-drawer class=" max-w-full lg:4/5 sm:w-full md:w-5/6 w-full z-999"      fixed [mode]="'over'" [name]="'newClientDrawer'"
    [position]="'right'" #recipeDrawer>
  <div class="flex flex-col w-full">
    

  
      <div class="flex flex-col w-full bg-card">
        <div class="flex flex-row items-center px-6 h-15 min-h-10 text-4xl text-accent"> 
          
            
            

            <div class="text-2xl font-semibold tracking-tight">DETALLE ORDEN</div>
            <button class="ml-auto" mat-icon-button (click)="recipeDrawer.close()">
             
            </button>
        </div>
          
     
      <div class="flex flex-col w-full">
        LLAMO ORDEN Y COTIZAR DESDE ACA CON EL NUMERO DE ORDEN:{{ order }}
      </div> 
    </div> 
  </div>
</fuse-drawer>





